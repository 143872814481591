import React from 'react';
import { Link } from "react-router-dom";

import { Loader } from "components/result";
import Icons from "components/icons";

import './card-preview.css';


const ActionExecutor = (props) => {

    const {
        actionWrap,
        action,
        children
    } = props;

    if( actionWrap === 'button' ){

        return(
            <button onClick={ action } >
                { children }
            </button>
        );
    }

    return (
        <Link to={ action }>
            { children }
        </Link>
    );

};

const CardPreview = (props) => {

    const {
        post,
        counters,
        title,
        path,
        fullPath,
        action = false,
        loading = false
    } = props;

    let readyPath;

    if(fullPath){
        readyPath = fullPath;
    } else{
        readyPath = path ? `${ path }${ post.slug }` : `/post/${ post.order_slug }`
    }

    return (
        <div className={ `card-preview ${ post.type } ${loading ? 'loading' : ""}` }>

            <ActionExecutor
                action={ action ? action : readyPath }
                actionWrap={ action ? 'button' : false }
            >
                { post.type === "news" &&
                    <div className="badge">
                        <Icons.News type="platform"/> NEWS
                    </div>
                }
                <div className="card-image">
                    <img src={ post.media[0]?.thumbnail } alt={ post.title } />
                </div>
                <div className="card-data">
                    { loading &&
                        <Loader />
                    }
                    { !loading && title && post.title && <h3>{ post.title }</h3> }
                    { !loading && counters &&
                        <ul className="card-counters">
                            <li>
                                <Icons.Like filled />
                                <span>{ post.like_count }</span>
                            </li>
                            <li>
                                <Icons.Comment filled />
                                <span>{ post.comment_count }</span>
                            </li>
                            <li>
                                <Icons.Document filled />
                                <span>{ post.attached_file_count }</span>
                            </li>
                        </ul>
                    }
                </div>
                <div className='card-icons'>
                    { post.media.some(({status}) => status !== null) &&
                        <div className="card-with-video site-icon"><Icons.VideoFill/></div>
                    }
                    { post.media.length > 1 &&
                        <div className="card-more-images site-icon"><Icons.MultiImage/></div>
                    }
                </div>
                    { post.attached_file_count > 0 &&
                        <div className="card-with-attachments site-icon"><Icons.Document filled /></div>
                    }
            </ActionExecutor>
        </div>
    );
};

export default CardPreview;