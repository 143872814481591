import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_SUMMARY_STATISTIC } from "graphql/query/statistic-gql";

import { Loader } from "../result";
import { Localize } from "components/service";

import './summary-statistic.css';


const SummaryLabels = {
    knowledge_count : <Localize>SUMMARY_COUNTERS.Label_KNYS</Localize>,
    users_count : <Localize>SUMMARY_COUNTERS.Label_Users</Localize>,
    posts_count : <Localize>SUMMARY_COUNTERS.Label_Posts</Localize>,
    media_count : <Localize>SUMMARY_COUNTERS.Label_Images</Localize>,
    video_count : <Localize>SUMMARY_COUNTERS.Label_Videos</Localize>,
    attachments_count : <Localize>SUMMARY_COUNTERS.Label_Documents</Localize>,
    comments_count : <Localize>SUMMARY_COUNTERS.Label_Comments</Localize>,
    collections_count : <Localize>SUMMARY_COUNTERS.Label_Collections</Localize>,
    teams_count : <Localize>SUMMARY_COUNTERS.Label_Teams</Localize>,
    hashtags_count : <Localize>SUMMARY_COUNTERS.Label_Hashtags</Localize>
};

const SummaryStatisticList = ({ summaryStatistic }) => {

    const ListItems = summaryStatistic.map( ({id, key, value }) => {
        let shortValue = 0;

        if(key !== "knowledge_count"){
            shortValue = value >= 1000 ? `${ (value / 1000).toFixed(1) }K`: value;
        } else{
            shortValue = `${value}`
        }

        const title = Localize({children: SummaryLabels[key]?.props.children })?.props.children;

        return(
            <li key={id} title={ `${ title }: ${value}` }>
                <div className="value site-title-color">{ shortValue }</div>
                <h5 className="label site-sub-text-color">{ SummaryLabels[key] }</h5>
            </li>
        );
    });

    return(
        <ul className="summary-statistic-list">
            { ListItems }
        </ul>
    );
};

const SummaryStatistic = () => {

    const { loading, data } = useQuery(GET_SUMMARY_STATISTIC, {
        fetchPolicy:"cache-and-network",
    });

    const {
        summaryStatistic = {}
    } = data !== undefined ? data  : {};

    return (
        <div className="summary-statistic-holder">
            { loading ?
                <Loader /> :
                <SummaryStatisticList summaryStatistic={ summaryStatistic } />
            }
        </div>
    );
};

export default SummaryStatistic;