import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Button, Drawer, Input, Icon } from "antd";
import { POST_MEDIA_UPDATE_FIELD, POST_DELETE_MEDIA, POST_MEDIA_SET_ORDER } from "graphql/mutation/post-gql";
import { GET_POST_SHORT } from "graphql/query/post-gql"
import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";


import './post-manage-images.css';

const PostManageImages = ({ postSlug, postId, defaultMedia, loadingQuery }) => {

    const client = useApolloClient();
    const [ showDrawer, setShowDrawer ] = useState(false);

    const [ deleteMedia ] = useMutation(POST_DELETE_MEDIA);
    const [ _setMutationMediaUpdateField, { loading } ] = useMutation(POST_MEDIA_UPDATE_FIELD,
        {
            update(cache, { data }) {

                const {
                    mediaUpdateField: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    const handleClick = (id) => {
        if (defaultMedia.length > 1)
        {
            deleteMedia({
                variables: {
                    media_id: id
                },
                update: (cache, { data }) => {

                    const {
                        deleteMedia: {
                            label,
                            message
                        }
                    } = data;

                    const { post } = client.readQuery({
                        query: GET_POST_SHORT,
                        variables: {
                            slug: postSlug,
                        }
                    });

                    const newMedia = post.media.filter(item => item.id !== id);

                    client.writeQuery({
                        query: GET_POST_SHORT,
                        data: {
                            post: { ...post, media: [ ...newMedia ] }
                        },
                    });

                    successNotification({
                        title: label,
                        description: message
                    });
                }
            }).catch((error) => {
                errorNotification(error);
            });

        }
    }

    const handleBlur = (e, id, description) => {

        if (!loading && e.target.value !== description)
        {
            _setMutationMediaUpdateField({
                variables: {
                    id,
                    fieldKey: 'description',
                    fieldValue: e.target.value
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    const [ _setMutationMediaSetOrder ] = useMutation(POST_MEDIA_SET_ORDER,
        {
            update(cache, { data }) {
                const {
                    mediaSetOrder: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label.toUpperCase(),
                    description: message
                });
            },
        });

    const initDataSource = !loadingQuery && defaultMedia;
    const [ dataSource, setDataSource ] = useState(initDataSource);

    useEffect(() => {
        setDataSource(initDataSource)
    }, [ initDataSource ]);



    const onSortEnd = ({ oldIndex, newIndex }) => {

        if (oldIndex !== newIndex)
        {
            const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);

            setDataSource(newData)

            const arrNewDataId = newData.map(({ id }) => id);


            _setMutationMediaSetOrder({
                variables: {
                    post_id: postId,
                    ids: arrNewDataId
                }
            }).catch((errorMutation) => {
                errorNotification(errorMutation);
            });

        }
    };

    const SortableItem = SortableElement(({ value }) => {

        const { id, thumbnail, description } = value;

        return (

            <li className="sortable-list-tab">
                <Icon type="menu" />
                <div className="col">
                    <div className="box-img">
                        <Icon type="delete"
                            onClick={ () => handleClick(id) }
                            style={ { color: 'white' } } />
                        <img src={ thumbnail } alt={ description } />
                    </div>
                </div>

                <div className="col">
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_Description</Localize>
                    </span>
                    <Input.TextArea
                        onBlur={ (e) => handleBlur(e, id, description) }
                        defaultValue={ description }
                        maxLength={ 64 }
                        placeholder="Enter short image description"
                        autoSize={ { minRows: 3, maxRows: 5 } }
                        showcount={
                            { formatter: ({ count, maxLength }) => maxLength - count }
                        }
                    />
                </div>
            </li>
        )
    });

    const SortableList = SortableContainer(() => {

        return (
            <ul className="sortable-list">
                {
                    defaultMedia.map((value) => {
                        const index = dataSource && dataSource.findIndex(x => x.id === value.id);
                        return <SortableItem key={ `item-${ value.id }` } index={ +index } value={ value } />
                    })
                }
            </ul>
        );
    });

    return (
        <div className="post-manage-images-wrap">

            <Button
                className="rounded-button"
                onClick={ () => setShowDrawer(true) }
            >
               <Localize>POST.Title_ManageMedia</Localize>
            </Button>
            <Drawer
                className="manage-images-drawer"
                title={
                    <>
                        <Localize>POST.Title_ManageMedia</Localize>
                        <button
                            className="close-drawer"
                            onClick={ () => setShowDrawer(false) }
                        >
                            <Icons.Close />
                        </button>
                    </>
                }
                placement="right"
                closable={ false }
                onClose={ () => setShowDrawer(false) }
                visible={ showDrawer }>

                <SortableList helperClass="sortable-list-tab-drag" onSortEnd={ onSortEnd } />

            </Drawer>
        </div>
    );

};

export default PostManageImages;