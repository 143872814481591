import React from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/react-hooks";

import { CREATE_POST, MEDIA_VIDEO_ADD } from "graphql/mutation/post-gql";

import { UpdateWrap } from "../../wraps";
import { PostCreateForm } from "../forms";
import { successNotification } from '../../result';

import './post-create.css';
import {Localize} from "../../service";


const PostCreate = () => {

    const history = useHistory();

    const [createPost, { loading: loadingQueryCreatePost }] = useMutation(CREATE_POST, {
        update(cache, { data }){

            const {createPost} = data;
            const {post, label, message} = createPost;

            successNotification({
                title: label,
                description: message
            });

            history.push(`/post/edit/${post.slug}`);

        }
    });

    const [createPostWithVideo, {loading: loadingQueryWithVideo }] = useMutation(MEDIA_VIDEO_ADD, {
        update(cache, { data }){

            const {mediaVideoAdd} = data;
            const {post, label, message} = mediaVideoAdd;

            successNotification({
                title: label,
                description: message
            });

            history.push(`/post/edit/${post.slug}`);

        }
    });

    return(
        <div className="post-create">
            <UpdateWrap>
                <Localize wrap="h2">POST.Title_ChooseMedia</Localize>
                <PostCreateForm
                    createPost={ createPost }
                    createPostWithVideo={ createPostWithVideo }
                    loadingQueryCreatePost={ loadingQueryCreatePost }
                    loadingQueryWithVideo={loadingQueryWithVideo}
                />
            </UpdateWrap>
        </div>
    );
};

export default PostCreate;