import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { Button, Form, Input, Select, DatePicker } from "antd";

import { USER_UPDATE } from "graphql/mutation/user-gql";
import { GET_ME, GET_LANGUAGES } from "graphql/query/user-gql";

import Mention from "components/mentions";
import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";

const formSubmit = ({ event, form, userUpdateRequest, myData }) => {
  event.preventDefault();

  form.validateFields((err, values) => {
    if (!err) {
      userUpdateRequest({
        variables: {
          input: {
            id: myData.id,
            name: values.fullName,
            salutation: values.salutation,
            nickname: values.nickname,
            locale: values.language,
            gender: values.gender,
            date_birth_at: values.date_birth_at
              ? values.date_birth_at.format("YYYY-MM-DD")
              : undefined,
            phone: values.phone,
            job_type: values.job_type,
            autohaus_url: values.dealershipURL,
            about: values.about,
          },
        },
      }).catch((error) => {
        errorNotification(error);
      });
    }
  });
};

const NormalSettingsForm = ({ myData, form }) => {
  const history = useHistory();

  const [userUpdateRequest, { loading }] = useMutation(USER_UPDATE, {
    update: function (cache, { data }) {
      const { me } = cache.readQuery({ query: GET_ME });

      const {
        userUpdate: { label, message, user },
      } = data;

      cache.writeQuery({
        query: GET_ME,
        data: {
          me: { ...me, ...user },
        },
      });
      successNotification({
        title: label,
        description: message,
      });
    },
  });
  const { getFieldDecorator } = form;

  const { Option } = Select;

  const { loading: langLoading, data: { languageActive } = {} } =
    useQuery(GET_LANGUAGES);

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <div>
      <Form
        onSubmit={(event) => {
          formSubmit({ event, form, userUpdateRequest, myData });
        }}
        className="profile-form settings"
        layout="horizontal"
      >
        <div className="grid-two-col">
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Select_Label_Salutation</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("salutation", {
              rules: [
                {
                  required: true,
                  message: <Localize>FORM_RULES.Required_Salutation</Localize>,
                },
              ],
              initialValue: myData.salutation,
            })(
              <Select
                placeholder={
                  Localize({ children: "FORMS.Input_Placeholder_Choose" }).props
                    .children
                }
                suffixIcon={<Icons.ArrowDown />}
              >
                <Option value="Mr.">
                  <Localize>FORMS.Select_OptionLabel_Mr</Localize>
                </Option>
                <Option value="Mrs.">
                  <Localize>FORMS.Select_OptionLabel_Mrs</Localize>
                </Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Input_Label_FullName</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("fullName", {
              rules: [
                {
                  min: 3,
                  message: (
                    <Localize minLength={3}>FORM_RULES.Min_FullName</Localize>
                  ),
                },
                {
                  required: true,
                  message: <Localize>FORM_RULES.Required_FullName</Localize>,
                },
              ],
              initialValue: myData.name,
            })(
              <Input
                placeholder={
                  Localize({ children: "FORMS.Input_Placeholder_FullName" })
                    .props.children
                }
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Select_Label_Gender</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("gender", {
              rules: [
                {
                  required: true,
                  message: <Localize>FORM_RULES.Required_Gender</Localize>,
                },
              ],
              initialValue: myData.gender,
            })(
              <Select
                placeholder={
                  Localize({ children: "FORMS.Input_Placeholder_Gender" }).props
                    .children
                }
                suffixIcon={<Icons.ArrowDown />}
              >
                <Option value="male">
                  <Localize>FORMS.Select_OptionLabel_Male</Localize>
                </Option>
                <Option value="female">
                  <Localize>FORMS.Select_OptionLabel_Female</Localize>
                </Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Input_Label_Username</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("nickname", {
              rules: [
                {
                  min: 3,
                  message: (
                    <Localize minLength={3}>FORM_RULES.Min_Username</Localize>
                  ),
                },
                {
                  type: "string",
                  pattern: /^[a-z\d_.-]+$/,
                  message: <Localize>FORM_RULES.RegExp_Username</Localize>,
                },
                {
                  required: true,
                  message: <Localize>FORM_RULES.Required_Username</Localize>,
                },
              ],
              initialValue: myData.nickname,
            })(
              <Input
                placeholder={
                  Localize({ children: "FORMS.Input_Placeholder_Username" })
                    .props.children
                }
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Input_Label_Email</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: <Localize>FORM_RULES.Email</Localize>,
                },
                {
                  required: true,
                  message: <Localize>FORM_RULES.Required_Email</Localize>,
                },
              ],
              initialValue: myData.email,
            })(
              <Input
                placeholder={
                  Localize({ children: "FORMS.Input_Placeholder_Email" }).props
                    .children
                }
                disabled
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Input_Label_Phone</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator(`phone`, {
              rules: [
                {
                  type: "string",
                  pattern: /^[\d\-+ ]+$/,
                  message: <Localize>FORM_RULES.ContactPhone</Localize>,
                },
              ],
              initialValue: myData.phone || "",
            })(
              <Input
                placeholder={
                  Localize({ children: "FORMS.Input_Placeholder_Phone" }).props
                    .children
                }
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Select_Label_Language</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("language", {
              rules: [
                {
                  required: true,
                  message: <Localize>FORM_RULES.Required_Language</Localize>,
                },
              ],
              initialValue: myData.locale,
            })(
              <Select
                loading={langLoading}
                placeholder={
                  Localize({ children: "FORMS.Input_Placeholder_Language" })
                    .props.children
                }
                suffixIcon={<Icons.ArrowDown />}
              >
                {languageActive !== undefined &&
                  languageActive.map(({ id, slug, name }) => {
                    return (
                      <Option value={slug} key={id}>
                        {name}
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Input_Label_DateBirth</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("date_birth_at", {
              initialValue:
                myData.date_birth_at &&
                moment(myData.date_birth_at, "YYYY-MM-DD"),
            })(<DatePicker format="DD.MM.YYYY" />)}
          </Form.Item>
        </div>

        {myData.brandArray && (
          <Form.Item
            label={
              <span className="site-label-text-color">
                <Localize>FORMS.Input_Label_CompanyName</Localize>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("fullName", {
              initialValue: myData.brand,
            })(<Input disabled />)}
          </Form.Item>
        )}
        <Form.Item
          label={
            <span className="site-label-text-color">
              <Localize>FORMS.Input_Label_JobTitle</Localize>
            </span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("job_type", {
            initialValue: myData.job_type,
          })(
            <Select
              placeholder={
                Localize({ children: "FORMS.Input_Placeholder_Choose" }).props
                  .children
              }
              suffixIcon={<Icons.ArrowDown />}
            >
              <Option value={""}>
                <Localize>GLOBAL.Select_OptionLabel_NotProvided</Localize>
              </Option>
              <Option value="CEO">
                <Localize>FORMS.Select_OptionLabel_CEO</Localize>
              </Option>
              <Option value="CSellingOwner">
                <Localize>FORMS.Select_OptionLabel_CSellingOwner</Localize>
              </Option>
              <Option value="BranchManager">
                <Localize>FORMS.Select_OptionLabel_BranchManager</Localize>
              </Option>
              <Option value="SalesManager">
                <Localize>FORMS.Select_OptionLabel_SalesManager</Localize>
              </Option>
              <Option value="CertifiedCarSalesman">
                <Localize>
                  FORMS.Select_OptionLabel_CertifiedCarSalesman
                </Localize>
              </Option>
              <Option value="Salesperson">
                <Localize>FORMS.Select_OptionLabel_Salesperson</Localize>
              </Option>
              <Option value="ServiceConsultant">
                <Localize>FORMS.Select_OptionLabel_ServiceConsultant</Localize>
              </Option>
              <Option value="OwnerShareholder">
                <Localize>FORMS.Select_OptionLabel_OwnerShareholder</Localize>
              </Option>
              <Option value="CarMaster">
                <Localize>FORMS.Select_OptionLabel_CarMaster</Localize>
              </Option>
              <Option value="Marketing">
                <Localize>FORMS.Select_OptionLabel_Marketing</Localize>
              </Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={
            <span className="site-label-text-color">
              <Localize>FORMS.Input_Label_DealerWebsite</Localize>
            </span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("dealershipURL", {
            initialValue: myData.autohaus_url,
          })(<Input placeholder="https://link.com" />)}
        </Form.Item>
        <Form.Item
          label={
            <span className="site-label-text-color">
              <Localize>FORMS.Textarea_Label_About</Localize>
            </span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("about", {
            initialValue: myData.about || "",
          })(
            <Mention
              placeholder={
                Localize({ children: "FORMS.Input_Placeholder_About" }).props
                  .children
              }
              rows="7"
            />
          )}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <div className="form-actions">
            <Button
              className="rounded-button"
              onClick={() => history.push(`/${myData.nickname}`)}
            >
              <Localize>GLOBAL.Button_Text_Cancel</Localize>
            </Button>
            <Button
              loading={loading}
              className="rounded-button"
              type="primary"
              htmlType="submit"
            >
              <Localize wrap>GLOBAL.Button_Text_Save</Localize>
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

const SettingsForm = Form.create({ name: "update_profile" })(
  NormalSettingsForm
);

export default SettingsForm;
