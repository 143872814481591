import React from "react";
import { Icon } from "antd";

const DiamondSvg = () => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 35 35">
    <path
      style={{
        fill: "#00C3FF",
      }}
      d="m31.739 8.616 -4.708 -5.65a1.126 1.126 0 0 0 -0.865 -0.405H5.834a1.126 1.126 0 0 0 -0.865 0.405L0.261 8.616a1.126 1.126 0 0 0 -0.021 1.415L15.114 29.008c0.451 0.575 1.321 0.575 1.772 0l14.874 -18.978a1.126 1.126 0 0 0 -0.021 -1.415"
    />
    <path
      style={{
        fill: "#40D2FF",
      }}
      points="256.001,40.971 165.951,149.031 346.051,149.031 "
      d="M16 2.561L10.372 9.314L21.628 9.314Z"
    />
    <path
      style={{
        fill: "#00AAF0",
      }}
      d="M10.372 9.314 5.38 2.659c-0.157 0.069 -0.299 0.172 -0.411 0.307L0.261 8.616a1.123 1.123 0 0 0 -0.26 0.699z"
    />
    <path
      style={{
        fill: "#80E1FF",
      }}
      d="m21.628 9.314 4.992 -6.656c0.157 0.069 0.299 0.172 0.411 0.307l4.708 5.65a1.123 1.123 0 0 1 0.26 0.699z"
    />
    <g>
      <path
        style={{
          fill: "#00C3FF",
        }}
        d="M31.999 9.314H0.001a1.123 1.123 0 0 0 0.239 0.716L15.114 29.008c0.451 0.575 1.321 0.575 1.772 0l14.874 -18.978a1.124 1.124 0 0 0 0.239 -0.716"
      />
      <path
        style={{
          fill: "#00C3FF",
        }}
        d="M16 29.439c0.33 0 0.661 -0.144 0.886 -0.431l14.874 -18.978a1.123 1.123 0 0 0 0.239 -0.716H16z"
      />
    </g>
    <path
      style={{
        fill: "#00AAF0",
      }}
      d="m16.195 29.418 5.433 -20.104h-11.256l5.433 20.104c0.129 0.022 0.26 0.022 0.39 0"
    />
  </svg>
);

const DiamondIcon = (props) => {
  if (props.loading) {
    return <Icon type="loading" />;
  }

  return <Icon component={DiamondSvg} />;
};

export default DiamondIcon;
