import React from "react";
import { Icon } from "antd";

const GoldSvg = () => (
  <svg
    height="30px"
    width="30px"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 11.266 11.266"
    xmlSpace="preserve"
  >
    <g>
      <path
        style={{
          fill: "#BF392C",
        }}
        d="M10.386 0H7.217L2.641 6.689h3.169z"
      />
      <path
        style={{
          fill: "#E2574C",
        }}
        d="M0.88 0h3.169l4.577 6.689H5.457z"
      />
      <path
        style={{
          fill: "#EFC75E",
        }}
        d="M5.809 3.521c2.147 0 3.873 1.725 3.873 3.873s-1.725 3.873 -3.873 3.873 -3.873 -1.725 -3.873 -3.873 1.725 -3.873 3.873 -3.873"
      />
      <path
        style={{
          fill: "#D7B354",
        }}
        d="M5.809 4.577c-1.549 0 -2.817 1.267 -2.817 2.817s1.267 2.817 2.817 2.817 2.817 -1.267 2.817 -2.817S7.359 4.577 5.809 4.577m0 4.929c-1.161 0 -2.113 -0.951 -2.113 -2.113S4.647 5.28 5.809 5.28s2.113 0.951 2.113 2.113 -0.951 2.113 -2.113 2.113"
      />
    </g>
  </svg>
);

const GoldIcon = (props) => {
  if (props.loading) {
    return <Icon type="loading" />;
  }

  return <Icon component={GoldSvg} />;
};

export default GoldIcon;
