import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

import UserHeaderActions from "./user-header-actions";
import UserHeaderActionsSelf from "./user-header-actions-self";
import AvatarPhoto from "../../avatar/avatar-photo";
import { LinkedMention, Localize } from "../../service";
import { Loader } from "../../result";
import Crown from "assets/gold-status.png";
import BrandOra from "assets/ora-logo.png";
import BrandWey from "assets/wey-logo.png";

import "./user-header.css";

const HeaderActions = ({ itsMe, user }) => {
  if (itsMe) {
    return <UserHeaderActionsSelf />;
  }

  return (
    <UserHeaderActions
      follow={user.me_following}
      userId={user.id}
      userNickname={user.nickname}
    />
  );
};


const UserHeader = (props) => {
  const { loading, userLogin, itsMe, user } = props;

  const brand = user?.brandArray?.filter((item) => item && item !== "0");

  return (
    <div className="user-header profile">
      {loading ? (
        <Loader />
      ) : (
        <Row className={user.status === "block" ? "blocked" : ""}>
          <Col xs={24} sm={24} md={8} span={8} className="user-header-photo">
            <AvatarPhoto
              photoUrl={user.avatar}
              size={150}
              initials={user.initials}
              blocked={user.status === "block" ? "big" : null}
            />
          </Col>
          {user.status === "block" ? (
            <Col xs={24} sm={24} md={16} span={16} className="user-header-info">
              <h1>{userLogin}</h1>
              <h4 className="site-sub-text-color">
                <Localize>GLOBAL.Text_InactiveUser</Localize>
              </h4>
            </Col>
          ) : (
            <Col xs={24} sm={24} md={16} span={16} className="user-header-info">
              <div className="user-header-data">
                <h1>{userLogin}</h1>
                <HeaderActions itsMe={itsMe} user={user} />
              </div>
              <ul className="user-header-statistics company-link-hover">
                <li className="post-amount-holder site-sub-text-color">
                  <Link to={`/${userLogin}`}>
                    <b className="site-bold-color">{user.counters.published}</b>
                    <Localize wrap>MENUS.Label_Posts</Localize>
                  </Link>
                </li>
                <li className="followers-holder site-sub-text-color">
                  <Link to={`/${user.nickname}/followers`}>
                    <b className="site-bold-color">{user.counters.followers}</b>
                    <Localize wrap>MENUS.Label_Followers</Localize>
                  </Link>
                </li>
                <li className="following-holder site-sub-text-color">
                  <Link to={`/${user.nickname}/following/users`}>
                    <b className="site-bold-color">{user.counters.following}</b>
                    <Localize wrap>MENUS.Label_Following</Localize>
                  </Link>
                </li>
              </ul>
              <div className="user-header-about">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {user.name && (
                    <h2 className="user-header-name">{user.name}</h2>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {user.diamond_status && (
                    <div className="diamond-status">
                      <Localize wrap>GLOBAL.Text_GoldStatus</Localize>{" "}
                      <img
                        className="anticon"
                        src={Crown}
                        alt="Crown"
                        width="25"
                        height="25"
                      />
                    </div>
                  )}
                </div>

                <div className="user-header-contact">
                  {user.dealer && user.dealer.company_name && (
                    <p className="dealer-name site-title-color">
                      {user.dealer.company_name}
                    </p>
                  )}
                  {!!user.autohaus_url && (
                    <p>
                      <a
                        href={`${user.autohaus_url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="outer-link"
                      >
                        {user.autohaus_url}
                      </a>
                    </p>
                  )}
                  {!!user.job_type && (
                    <p className="job-description site-sub-text-color">
                      <Localize>{`FORMS.Select_OptionLabel_${user.job_type}`}</Localize>
                    </p>
                  )}
                  {!!user.brand && (
                    <p
                      className="job-description site-sub-text-color"
                      style={{ color: "black" }}
                    >
                      {user.brand}
                    </p>
                  )}
                  {!!user.email && (
                    <p>
                      <a
                        href={`mailto:${user.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="outer-link"
                      >
                        {user.email}
                      </a>
                    </p>
                  )}
                  {!!user.phone && (
                    <p>
                      <a
                        href={`tel:${user.phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="outer-link"
                      >
                        {user.phone}
                      </a>
                    </p>
                  )}
                </div>
                {user.about ? (
                  <div className="user-header-description">
                    <LinkedMention searchSigns={["@", "^", "#"]}>
                      {user.about}
                    </LinkedMention>
                  </div>
                ) : null}
              </div>
            </Col>
          )}
        </Row>
      )}

      {false && (
        <div className="brand">
          {brand.includes("ORA") && brand.includes("WEY") ? (
            <div>
              <img src={BrandOra} alt="Ora" width={80} height={80} />
              <img src={BrandWey} alt="Wey" width={80} height={80} />
            </div>
          ) : brand.includes("ORA") ? (
            <img src={BrandOra} alt="Ora" width={80} />
          ) : (
            <img src={BrandWey} alt="Wey" width={80} />
          )}
        </div>
      )}
    </div>
  );
};

export default UserHeader;
