import HomeIcon from "./home-icon";
import NotificationIcon from "./notification-icon";
import PlusIcon from "./plus-icon";
import UserIcon from "./user-icon";
import SearchIcon from "./search-icon";
import LikeIcon from "./like-icon";
import ShareIcon from "./share-icon";
import CloseIcon from "./close-icon";
import CommentIcon from "./comment-icon";
import {
    ArrowLeftIcon, ArrowRightIcon,
    ArrowDownIcon, ArrowUpIcon
} from "./arrows-icon";
import FileIcon from "./files-icon";
import DownloadIcon from "./download-icon";
import SettingsIcon from "./settings-icon";
import CollectionIcon from "./collection-icon";
import PostIcon from "./posts-icon";
import DocumentIcon from "./document-icon";
import LockIcon from "./lock-icon";
import NoImageIcon from "./no-image-icon";
import HashtagIcon from "./hashtag-icon";
import MultiImageIcon from "./multi-image-icon";
import PublicIcon from "./public-icon";
import ViewIcon from "./view-icon";
import FollowersIcon from "./followers-icon";
import TrashIcon from "./trash-icon";
import ScaleIcon from "./scale-icon";
import ActionStatusIcon from "./action-status-icon";
import NoContentIcon from "./no-content-icon";
import NewsIcon from "./news-icon";
import EventsIcon from "./events-icon";
import ContactIcon from "./contact-icon";
import CompetitionIcon from "./competition-icon";
import RankingIcon from "./ranking-icon";
import ChatIcon from "./chat-icon";
import AttachFileIcon from "./attach-file-icon";
import CheckIcon from "./check-icon";
import SignOutIcon from "./sign-out-icon";
import PlusNewImageIcon from "./plus-new-image-icon"
import VideoSrokeIcon from "./video-stroke-icon"
import VideoFillIcon from "./video-fill-icon"
import PlayVideoArrowIcon from "./play-video-arrow-icon"
import VideoProcessingIcon from "./video-processing-icon"
import NoVideoCoverIcon from "./no-video-cover-icon"
import DiamondIcon from "./diamond-icon"
import GoldIcon from "./gold-icon"


const Icons = {
    Home : HomeIcon,
    Notification : NotificationIcon,
    Plus : PlusIcon,
    User : UserIcon,
    Search : SearchIcon,
    Like : LikeIcon,
    Share : ShareIcon,
    Close : CloseIcon,
    Comment : CommentIcon,
    ArrowLeft : ArrowLeftIcon,
    ArrowRight : ArrowRightIcon,
    ArrowUp : ArrowUpIcon,
    ArrowDown : ArrowDownIcon,
    File : FileIcon,
    Download : DownloadIcon,
    Settings : SettingsIcon,
    Collection : CollectionIcon,
    Post : PostIcon,
    Document : DocumentIcon,
    Lock : LockIcon,
    Public : PublicIcon,
    NoImage : NoImageIcon,
    Hashtag : HashtagIcon,
    View : ViewIcon,
    MultiImage : MultiImageIcon,
    Followers : FollowersIcon,
    Trash : TrashIcon,
    Scale : ScaleIcon,
    ActionStatus: ActionStatusIcon,
    NoContent: NoContentIcon,
    News : NewsIcon,
    Events : EventsIcon,
    Contact : ContactIcon,
    Competition: CompetitionIcon,
    Chat: ChatIcon,
    AttachFile: AttachFileIcon,
    Check : CheckIcon,
    Ranking: RankingIcon,
    SignOut: SignOutIcon,
    PlusNewImage: PlusNewImageIcon,
    VideoSroke: VideoSrokeIcon,
    VideoFill: VideoFillIcon,
    PlayVideoArrow: PlayVideoArrowIcon,
    VideoProcessing: VideoProcessingIcon,
    NoVideoCover: NoVideoCoverIcon,
    Diamond: DiamondIcon,
    Gold: GoldIcon
};

export default Icons;