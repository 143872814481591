import React from "react";
import { Icon } from "antd";

const NewsSvg = () => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M17.9,10.4l-1.5-6.7c-0.1-0.5-0.5-0.8-0.9-0.9c-0.5-0.1-0.9,0.1-1.2,0.5c-0.9,1.3-3.5,4.5-6.9,5.3L1.7,9.9
		c-0.9,0.2-1.4,1.1-1.2,2l1.1,4.9c0.2,0.9,1.1,1.4,2,1.2l0.9-0.2L6,20.7c0.2,0.5,0.6,0.8,1.1,1c0.2,0.1,0.5,0.1,0.7,0.1
		c0.3,0,0.5-0.1,0.8-0.2c1-0.4,1.4-1.6,1-2.6l-1.2-2.2l0.8-0.2c3.5-0.8,7.2,0.9,8.6,1.7c0.4,0.2,0.9,0.2,1.3-0.1
		c0.4-0.3,0.5-0.8,0.4-1.2L17.9,10.4z M3.3,16.5c-0.1,0-0.2,0-0.2-0.1l-1.1-4.9c0-0.1,0-0.2,0.1-0.2L7,10.2l1.3,5.1l-1,0.2
		c0,0,0,0,0,0l-2,0.5l0,0l-0.6,0.1l-0.3,0.1c0,0,0,0,0,0L3.3,16.5z M8,20.3c-0.1,0-0.2,0.1-0.3,0c-0.1,0-0.2-0.1-0.3-0.3L6,17.4
		l0.9-0.2l1.3,2.5C8.3,19.9,8.2,20.2,8,20.3z M9.7,15L8.4,9.8c3.1-1,5.3-3.4,6.6-5.1l1.4,6l1.5,6C16,15.7,12.9,14.6,9.7,15z"
    />
    <path
      d="M19.5,9.2c-0.4,0.1-0.7,0.5-0.6,0.9c0.1,0.3,0.4,0.6,0.7,0.6c0.1,0,0.1,0,0.2,0l2.4-0.6c0.4-0.1,0.7-0.5,0.6-0.9
		c-0.1-0.4-0.5-0.7-0.9-0.6L19.5,9.2z"
    />
    <path
      d="M19.1,8.1c0.1,0,0.3,0,0.4-0.1l2.1-1.3c0.3-0.2,0.5-0.7,0.2-1c-0.2-0.3-0.7-0.5-1-0.2l-2.1,1.3c-0.3,0.2-0.5,0.7-0.2,1
		C18.6,8,18.8,8.1,19.1,8.1z"
    />
    <path
      d="M22.9,12.1l-2.5-0.3c-0.4,0-0.8,0.3-0.8,0.7s0.3,0.8,0.7,0.8l2.5,0.3c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.7
		C23.6,12.6,23.3,12.2,22.9,12.1z"
    />
  </svg>
);

const PlatformNewsSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="24"
    viewBox="0 0 1714 1024"
  >
    <g id="icomoon-ignore"></g>
    <path
      fill="#fff"
      d="M859.123 1.72v0c-471.297 0-856.904 205.657-856.904 574.126s0 12.854 0 17.138v8.569c0 0 0 4.285 0 8.569s0 8.569 0 12.854c8.569 55.699 34.276 149.958 119.967 239.933v0c64.268 59.983 175.665 137.107 347.046 154.243 102.828 12.854 179.95 0 231.364-21.423 21.423-8.569 34.276-21.423 51.414-29.992 17.138-12.854 29.992-29.992 38.561-42.845 17.138-29.992 25.707-55.699 29.992-68.552 0-4.285 0-17.138 4.285-29.992v0c4.285-64.268 4.285-192.803 4.285-312.771 0-38.561 0-77.121 0-111.397 0-64.268 0-128.536 0-128.536-81.406 34.276-308.485 171.381-308.485 171.381v244.218c-4.285 0-4.285 149.958-184.234 128.536 0 0-167.096 0-222.795-197.088-8.569-25.707-12.854-55.699-12.854-81.406 0-269.925 317.054-471.297 758.36-471.297s758.36 201.372 758.36 471.297 0 55.699-12.854 81.406c-59.983 192.803-222.795 197.088-222.795 197.088-179.95 21.423-179.95-128.536-179.95-128.536v-244.218c-4.285 0-231.364-137.107-312.771-171.381 0 0 0 64.268 0 128.536 0 34.276 0 72.837 0 111.397 0 119.967 0 248.502 4.285 308.485v0c0 17.138 0 25.707 4.285 29.992 4.285 12.854 8.569 38.561 29.992 68.552 8.569 17.138 21.423 29.992 38.561 42.845 12.854 12.854 29.992 21.423 51.414 29.992 47.13 21.423 124.251 34.276 227.079 21.423 175.665-17.138 287.063-94.259 347.046-154.243v0c85.69-94.259 111.397-184.234 119.967-239.933 0-4.285 0-8.569 0-12.854 0 0 0-4.285 0-8.569v-8.569c0-4.285 0-12.854 0-17.138 4.285-364.184-381.322-569.841-852.619-569.841v0z"
    ></path>
  </svg>
);

const NewsIcon = (props) => {
  if (props.type === "platform") {
    return <Icon component={PlatformNewsSvg} />;
  }

  return <Icon component={NewsSvg} />;
};

export default NewsIcon;